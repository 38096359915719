import { ApiResponse, PaginatedApiResponse, PaginatedApiRequest } from './ServiceModels';
import { NotificationType } from './CompanyOnboardingModels';

/**
 * API response for the POST /upload-file to import users or devices
 */
export interface ImportResponse extends ApiResponse {
  errorLines?: ErrorLine[];
  successfulLines?: number;
  uploadType: UploadType;
  uploadId: string;
  numberOfErrorUsers?: number;
  numberOfErrorEids?: number;
  numberOfSuccessfulUsers?: number;
  numberOfSuccessfulEids?: number;
  companyId: string;
  hasErrors?: boolean;
  errorsPage?: ErrorsPage;
}

export interface ErrorLine {
  line: number;
  reasons: number[];
}

export interface ErrorsPage extends PaginatedApiResponse {
  errors: BulkImportError[];
}

export interface BulkImportError {
  uploadId: string;
  numberOfLine: number;
  phoneNumber: ErrorField;
  userEmail: ErrorField;
  eid: ErrorField;
  validRecord: boolean;
}

interface ErrorField {
  value: string;
  error: boolean;
  reasons: number[] | null;
  duplicatedLines: number[] | null;
}

/**
 * API request to import users or devices
 */
export interface ImportRequest {
  file: File;
}

/**
 * Types of file upload templates
 */
export enum UploadType {
  'USERS' = 'USERS',
  'DEVICES' = 'DEVICES',
  'NEW_CONNECTIONS' = 'NEW_CONNECTIONS',
  'SIM_SWAPS' = 'SIM_SWAPS',
}

/**
 * Mapping of upload type to file name
 */
export const UploadFileNames = {
  [UploadType.USERS]: 'USERS',
  [UploadType.DEVICES]: 'DEVICES',
  [UploadType.NEW_CONNECTIONS]: 'NEW_CONNECTIONS',
  [UploadType.SIM_SWAPS]: 'SIM_SWAPS',
};

/**
 * Request interface for the confirm new connections import
 */
export interface ConfirmNewConnectionsRequest {
  uploadId: string;
  uploadType: UploadType;
  notifyAdmin: boolean;
  notifyDistributionList: boolean;
}

/**
 * Request interface for the confirm duplicated users import
 */
export interface ConfirmDuplicatedUsersRequest {
  uploadId: string;
  confirm: boolean;
  uploadType: UploadType;
}

/**
 * Request interface for the confirm new connections import
 */
export interface ConfirmBulkImportRequest {
  uploadId: string;
  uploadType: UploadType;
  notifyAdmin: boolean;
  notifyDistributionList: boolean;
  notificationType: '' | 'PULL' | 'PUSH';
  excludedLines: number[];
  qrCodeNotification: NotificationType;
  confirmTransfer: boolean;
  tariffCode: string;
}

/**
 * Request interface for a bulk import row update request
 */
export interface UpdateBulkImportRowRequest {
  uploadId: string;
  uploadType: UploadType;
  numberOfLine: number;
  phoneNumber: string;
  userEmail: string;
  eid: string;
}

/**
 * Request interface for a bulk import request
 */
export interface BulkImportRowRequest {
  uploadId: string;
  uploadType: UploadType;
  notificationType: '' | 'PULL' | 'PUSH';
  notifyAdmin: boolean;
  notifyDistributionList: boolean;
  qrCodeNotification: NotificationType;
  excludedLines: number[] | null;
}

/**
 * API response for the POST /update-row to update a bulk import error
 */
export interface UpdateBulkImportRowResponse extends BulkImportError, ApiResponse {}

/**
 * Request interface for the valid rows of a bulk import
 */
export interface BulkImportValidRowsRequest extends PaginatedApiRequest {
  uploadId: string;
  uploadType: UploadType;
}

export interface BulkImportDetails {
  simId: string;
  deviceId: string;
  eid: string;
  serialNumber: string;
  manufacturer: string;
  model: string;
  os: string;
  userId: string;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  phoneNumber?: string;
}
export interface Content {
  uploadId: string;
  numberOfLine: number;
  ban: string;
  source: BulkImportDetails;
  target: BulkImportDetails;
}
/**
 * API response for the POST /valid-rows to retrive the valid rows of a sim swap import
 */
export interface BulkImportValidRowsResponse extends PaginatedApiResponse {
  excludedLines: number;
  ban: string;
  companyId: string;
  content: Content[];
  transfer: boolean;
}

export type UploadErrorType = 'DUPLICATED_USERS' | 'UNKNOWN_ERROR';

export const UploadErrorCode: Record<number, UploadErrorType> = {
  2073: 'DUPLICATED_USERS',
};
