import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ClickableLink from '../../../../../components/Text/ClickableLink';
import useSnackBar from '../../../../../hooks/useSnackBar/useSnackBar';
import { Device } from '../../../../../models/UserDevicesModels';
import CompanyOnboardingService from '../../../../../services/CompanyOnboarding/CompanyOnboardingService';
import UsersService from '../../../../../services/Users/UsersService';

function useRestoreConnection(
  onClose: () => void,
  onUpgradeSuccess: () => void,
  simId: string,
  device: Device | null | undefined,
) {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
  const [hasDL, setHasDL] = useState(false);
  const snackBar = useSnackBar();
  const [t] = useTranslation();
  /**
   * Perform the request to restore the connection
   */
  const restoreConnection = (notifyAdmin: boolean, notifyDL: boolean, phoneNumber: string) => {
    setIsLoading(true);
    const request = {
      userId: id as string,
      eid: simId,
      notifyAdmin,
      notifyDistributionList: notifyDL,
      phoneNumber,
    };
    UsersService.api
      .restoreConnection(request)
      .then(
        () => {
          snackBar.showSnackBar(t('pages.userDetails.restoreConnectionDrawer.restoreConnectionSuccess'), 'success');
          onUpgradeSuccess();
          onClose();
        },
        (err) => {
          if (err.code === 'ECONNABORTED') {
            snackBar.showSnackBar(t('pages.userDetails.restoreConnectionDrawer.restoreConnectionTimeout'), 'warning');
            onClose();
          } else {
            snackBar.showSnackBar(
              <Trans
                i18nKey="pages.userDetails.restoreConnectionDrawer.restoreConnectionError"
                components={{
                  HelpLink: (
                    <ClickableLink
                      onClick={() => {}}
                      to={`${import.meta.env.VITE_HELP_SUPPORT_URL}/help/Default_CSH.htm`}
                      target="_blank"
                    />
                  ),
                }}
              />,
              'error',
            );
          }
        },
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Fetches the list of notification settings of the company
   * @param companyId
   */
  const getNotificationSettings = (companyId: string) => {
    setIsLoadingNotifications(true);
    CompanyOnboardingService.api
      .fetchCompanyNotificationSettings(companyId)
      .then(
        (res) => {
          setHasDL(
            res.data.qrCodeNotifications.find((notification) => notification.type === 'DISTRIBUTION_LIST')?.enabled ??
              false,
          );
        },
        () => {
          snackBar.showSnackBar(
            t('pages.userDetails.upgradeDeviceDrawer.fetchCompanyNotificationsDefaultError'),
            'error',
          );
        },
      )
      .finally(() => setIsLoadingNotifications(false));
  };

  useEffect(() => {
    if (device?.companyId) {
      getNotificationSettings(device.companyId);
    }
  }, [device]);

  return { restoreConnection, isLoading, isLoadingNotifications, hasDL };
}

export default useRestoreConnection;
