import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import React, { createContext, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { decodeToken, isExpired } from 'react-jwt';
import { useDispatch } from 'react-redux';
import { SessionRenewThreshold } from '../../configs/PortalConfig';
import UseLogger from '../../hooks/useLogger/useLogger';
import useSnackBar from '../../hooks/useSnackBar/useSnackBar';
import { LogoutResponse, UserData, DecodedJwt } from '../../models/AuthModels';
import AuthService from '../../services/Auth/AuthService';
import { setNotificationsCounter } from '../../stores/NotificationsCenterStore';

interface AuthContextData {
  authenticate: (renewSession?: boolean, route?: string) => Promise<string | unknown>;
  renewCIAMSession: () => Promise<unknown>;
  logout: () => Promise<AxiosResponse<LogoutResponse> | unknown>;
  userData: UserData | null;
  jwt: string | null;
}

export const AuthContext = createContext({} as AuthContextData);

function AuthProvider({ children }: PropsWithChildren) {
  const snackbar = useSnackBar();
  const dispatch = useDispatch();
  const routesWithoutAuth = ['/404', '/notAllowed', '/auth', '/logout', '/privacypolicy', '/termsandconditions'];
  const [jwt, setJwt] = useState<string | null>(localStorage.getItem('jwt'));
  const [userData, setUserData] = useState<UserData | null>(null);

  /**
   * Requests the user session
   */
  const authenticate = async (renewSession = false, route = '') => {
    UseLogger().logInfo(`AuthContext: Performing /authenticate method to load the route: ${route}`);
    if (!jwt || isExpired(jwt) || renewSession) {
      if (userData) {
        UseLogger().logInfo(`AuthContext: User data already exists!`);
        const expiryTime = DateTime.fromMillis(userData.exp * 1000);
        const diff = expiryTime.diff(DateTime.now(), ['minutes']).toObject();
        if (diff.minutes && diff.minutes > SessionRenewThreshold) {
          UseLogger().logWarn(
            `AuthContext: No need to renew the session. Session is still valid for ${diff.minutes} minutes. `,
          );
          return jwt;
        }
      }
      UseLogger().logInfo(`AuthContext: Performing authenticate request...`);
      return new Promise((resolve, reject) => {
        AuthService.api.authenticate().then(
          (response) => {
            if (response.data.accessToken) {
              const token = response.data.accessToken;
              localStorage.setItem('jwt', token);
              setJwt(token);
              const decodedToken: DecodedJwt = decodeToken(token) as DecodedJwt;
              const userDataObj: UserData = {
                exp: decodedToken.exp,
                email: response.data.email,
                role: response.data.role,
                firstName: response.data.firstName,
                lastName: response.data.lastName,
                canResetPassword: response.data.canResetPassword,
              };
              setUserData(userDataObj);
              dispatch(setNotificationsCounter(response.data.unreadNotifications));
              UseLogger().logInfo(`AuthContext: Authentication done [jwt]: ${JSON.stringify(decodeToken(token))}`);
            }
            resolve(response);
          },
          (err) => {
            snackbar.showSnackBar('Authentication failed', 'error');
            reject(err);
          },
        );
      });
    }
    UseLogger().logInfo(`AuthContext: Authentication not needed! Token still valid.`);
    return jwt;
  };

  /**
   * SAML initializer
   */
  const initSaml = () => {
    UseLogger().logInfo('AuthContext: Verifying the need for login!');
    if (!routesWithoutAuth.includes(window.location.pathname) && (!jwt || isExpired(jwt))) {
      localStorage.removeItem('jwt');
      UseLogger().logInfo('AuthContext: Performing the login on SAML...');
      window.location.href = `${import.meta.env.VITE_SAML_URL}`;
    } else {
      authenticate().then(() => {});
      UseLogger().logInfo('AuthContext: No need to perform the login!');
    }
  };

  /**
   * Request the renewal of the CIAM session
   */
  const renewCIAMSession = () =>
    new Promise((resolve, reject) => {
      AuthService.api.renewCIAMSession().then(
        (res) => {
          UseLogger().logInfo(`AuthContext: CIAM session renewed successfully!`);
          resolve(res);
        },
        (err) => {
          UseLogger().logInfo(`AuthContext: Failed to renew the CIAM session!`);
          snackbar.showSnackBar('Failed to renew your session!', 'error');
          reject(err);
        },
      );
    });

  /**
   * Performs a logout action
   */
  const logout = async () =>
    new Promise((resolve, reject) => {
      AuthService.api.logout().then(
        (res) => {
          UseLogger().logInfo(`AuthContext: Logout successful: ${JSON.stringify(res)}`);
          localStorage.removeItem('jwt');
          resolve(res);
        },
        (err) => {
          UseLogger().logInfo(`AuthContext: Logout error: ${JSON.stringify(err)}`);
          reject(err);
        },
      );
    });

  /**
   * Retrives admin info from id
   */
  const fetchAdminInfo = () =>
    new Promise((resolve, reject) => {
      const token: DecodedJwt = decodeToken(localStorage.getItem('jwt') as string) as DecodedJwt;
      AuthService.api.fetchAdminInfo(token.sub).then(
        (response) => {
          const userDataObj: UserData = {
            exp: token.exp,
            email: response.data.email,
            role: response.data.role,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            canResetPassword: response.data.canResetPassword,
          };
          setUserData(userDataObj);
          resolve(response);
        },
        (err) => {
          UseLogger().logInfo(`AuthContext: Failed to fetch admin info. Error: ${JSON.stringify(err)}`);
          reject(err);
        },
      );
    });

  /**
   * User data initializer
   */
  const initUserData = () => {
    if (localStorage.getItem('jwt')) {
      fetchAdminInfo();
    }
  };

  useEffect(() => {
    UseLogger().log(`AuthContext: init auth context...`);
    initUserData();
    initSaml();
  }, []);

  const value = useMemo(
    () => ({
      authenticate,
      renewCIAMSession,
      logout,
      userData,
      jwt,
    }),
    [userData],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
export default AuthProvider;
