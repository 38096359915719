import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationSetting, NotificationType } from '../models/CompanyOnboardingModels';
import { ImportResponse, UploadErrorType, BulkImportError, BulkImportValidRowsResponse } from '../models/ImportModels';

export interface RequestParams {
  notifyAdmin: boolean;
  notifyDistributionList: boolean;
  qrCodeNotification: NotificationType;
  notificationType: '' | 'PULL' | 'PUSH';
  tariffCode: string;
  confirmTransfer: boolean;
}

interface ImportStateData {
  request: RequestParams;
  importResponse: ImportResponse | null;
  bulkImportErrors: BulkImportError[];
  bulkImportValidRowsResponse: BulkImportValidRowsResponse | null;
  qrCodeNotificationSettings: NotificationSetting[];
  hasDistributionList: boolean;
  uploadErrorType: UploadErrorType | null;
  companyId: string;
  excludedImports: number[];
  journeyStep: number;
  step: number;
  bulkImportStep: number;
}

const initialState: ImportStateData = {
  request: {
    notifyAdmin: true,
    notifyDistributionList: false,
    qrCodeNotification: 'ADMIN',
    notificationType: '',
    tariffCode: '',
    confirmTransfer: false,
  },
  importResponse: null,
  bulkImportErrors: [],
  bulkImportValidRowsResponse: null,
  qrCodeNotificationSettings: [],
  hasDistributionList: false,
  uploadErrorType: null,
  excludedImports: [],
  companyId: '',
  journeyStep: 1,
  step: 1,
  bulkImportStep: 1,
};

export const ImportSlice = createSlice({
  name: 'import',
  initialState,
  reducers: {
    reset: () => initialState,
    updateImportRequest: (state, action: PayloadAction<Partial<RequestParams>>) => {
      state.request = {
        ...state.request,
        ...action.payload,
      };
    },
    setImportResponse: (state, action: PayloadAction<ImportResponse | null>) => {
      state.importResponse = action.payload;
    },
    setBulkImportErrors: (state, action: PayloadAction<BulkImportError[]>) => {
      state.bulkImportErrors = action.payload;
    },
    setBulkImportValidRowsResponse: (state, action: PayloadAction<BulkImportValidRowsResponse | null>) => {
      state.bulkImportValidRowsResponse = action.payload;
    },
    setNotificationSettings: (state, action: PayloadAction<NotificationSetting[]>) => {
      state.hasDistributionList = action.payload.some((setting) => setting.type === 'DISTRIBUTION_LIST');
      state.qrCodeNotificationSettings = action.payload;
    },
    setUploadErrorType: (state, action: PayloadAction<UploadErrorType | null>) => {
      state.uploadErrorType = action.payload;
    },
    setExcludedImports: (state, action: PayloadAction<number[]>) => {
      state.excludedImports = action.payload;
    },
    setCompanyId: (state, action: PayloadAction<string>) => {
      state.companyId = action.payload;
    },
    setJourneyStep: (state, action: PayloadAction<number>) => {
      state.journeyStep = action.payload;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setBulkImportStep: (state, action: PayloadAction<number>) => {
      state.bulkImportStep = action.payload;
    },
  },
});

export const {
  reset,
  updateImportRequest,
  setImportResponse,
  setBulkImportErrors,
  setBulkImportValidRowsResponse,
  setNotificationSettings,
  setUploadErrorType,
  setExcludedImports,
  setCompanyId,
  setJourneyStep,
  setStep,
  setBulkImportStep,
} = ImportSlice.actions;

export default ImportSlice.reducer;
