import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { toggleDrawer } from '../../../stores/NotificationsCenterStore';
import Drawer from '../../Drawer/Drawer';
import EnvelopeIcon from '../../Icons/EnvelopeIcon';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import ClickableLinkWithIcon from '../../Text/ClickableLinkWithIcon';
import Notification from './Notification';
import { NotificationContainer, UnreadNotification } from './NotificationStyles';
import useNotificationsDrawer from './useNotificationsDrawer';

function NotificationsDrawer() {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { setNextPage, markNotificationAsRead, isLoading, hasMoreNotifications, notifications, drawerOpen } =
    useNotificationsDrawer();

  /**
   * Handle the close action
   */
  const handleClose = () => {
    dispatch(toggleDrawer());
  };

  /**
   * Renders a skeleton notification while loading
   */
  const renderSkeletonNotification = (key: number) => (
    <NotificationContainer key={key}>
      <Box sx={{ padding: '0.4rem 1rem 1rem 0rem' }}>
        <UnreadNotification read data-testid="unreadNotification" />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Skeleton width="10rem" height="2rem" />
        <Skeleton width="100%" height="6rem" />
        <Skeleton width="4rem" height="2rem" />
      </Box>
    </NotificationContainer>
  );

  return (
    <Drawer
      disablePortal
      open={drawerOpen}
      onClose={handleClose}
      onBackButton={handleClose}
      anchor="right"
      title={t('notificationsCenter.title')}
      progress={0}
      PaperProps={{ id: 'scrollable-container' }}
    >
      <Box sx={{ marginTop: '2rem' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '2rem' }}>
          {notifications?.length > 0 ? (
            <ClickableLinkWithIcon
              onClick={() => markNotificationAsRead()}
              sx={{ fontSize: '1.25rem', lineHeight: '1.75rem' }}
              rightIcon={<EnvelopeIcon />}
            >
              {t('notificationsCenter.markAllAsRead')}
            </ClickableLinkWithIcon>
          ) : null}
        </Box>
        {notifications?.length > 0 ? (
          <Box sx={{ paddingBottom: '2rem' }}>
            <InfiniteScroll
              dataLength={notifications.length}
              next={setNextPage}
              hasMore={hasMoreNotifications}
              loader={
                <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '2rem', paddingBottom: '2rem' }}>
                  <LoadingSpinner style={{ minHeight: '50px', maxWidth: '50px' }} />
                </Box>
              }
              endMessage={
                <Typography sx={{ textAlign: 'center', fontSize: '1rem', paddingTop: '2rem' }}>
                  {t('notificationsCenter.noMoreNotifications')}
                </Typography>
              }
              scrollableTarget="scrollable-container"
            >
              {notifications.map((n) => (
                <Notification notification={n} key={n.id} markAsRead={markNotificationAsRead} />
              ))}
            </InfiniteScroll>
          </Box>
        ) : (
          <div>
            {isLoading ? (
              <>{[...Array(4)].map((value, index) => renderSkeletonNotification(index))}</>
            ) : (
              <Typography>{t('notificationsCenter.noNotifications')}</Typography>
            )}
          </div>
        )}
      </Box>
    </Drawer>
  );
}

export default NotificationsDrawer;
